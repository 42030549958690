/* arguments inittial */
$color_body: #151515;
$color_link: #444444;
$color_heading: #dd0000;

$color_blue: #002CA5;
$color_blue2: #0866FF;
$color_blue3: #5367bd;
$color_blue4: #007FFF;
$color_blue5: #003190;
$color_blue6: #0145C6;
$color_blue7: #F7F8FC;
$color_red: #EA0D0D;
$color_red2: #FF0000;
$color_black1: #000;
$color_black: #151515;
$color_black02: #2B2B2B;
$color_black03: #1D1D1D;
$color_black04: #2F2F2F;
$color_gray: #87909B;
$color_gray02: #F3F3F3;
$color_gray03: #E6E6E6;
$color_gray04: #F7F7F7;
$color_gray05: #707070;
$color_gray06: #D3D3D3;
$color_gray07: #AAAAAA;
$color_gray08: #696969;
$color_gray09: #B2B2B2;
$color_gray10: #F4F4F4;
$color_gray11: #C7C7C7;
$color_gray12: #C1C1C1;
$color_gray13: #F2F2F2;
$color_gray14: #F8F8F8;
$color_gray15: #EDEDED;
$color_gray16: #757575;
$color_gray17: #EEEEEE;
$color_grayl: #DADBDC;
$color_white: #fff;
$color_green: #17A811;

$color_bg: #F0F1F2;
$color_bg_secondary: #000000;

$font_feature_settings_palt: false;
$font_smooth: true;

$img_url: '/wp/wp-content/themes/yamaha/assets/';

$browser_prefix: ("-webkit-", "-moz-", "-ms-", "-o-", "");
@mixin add_prefix($key, $value) {
  @each $prefix in $browser_prefix {
    #{$prefix}$key: $value;
  }
}

@mixin font-face($font-name, $path, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url($path+".eot");
    src: url($path+".eot?#iefix") format("embedded-opentype"), url($path+".woff")
        format("woff"), url($path+".ttf") format("truetype"), url($path+".svg##{$font-name}")
        format("svg");
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@include font-face(Inter, "/assets/fonts/Inter-Light", 300, normal);
@include font-face(Inter, "/assets/fonts/Inter-Regular", 400, normal);
@include font-face(Inter, "/assets/fonts/Inter-Medium", 500, normal);
@include font-face(Inter, "/assets/fonts/Inter-SemiBold", 600, normal);
@include font-face(Inter, "/assets/fonts/Inter-Bold", 700, normal);
@include font-face(Inter, "/assets/fonts/Inter-ExtraBold", 800, normal);

$inter: 'Inter', sans-serif;
/* font style */
@mixin ffInter{
  font-family: $inter;
}

@mixin font-smooth() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin off-font-smooth() {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

@mixin palt() {
  @include add_prefix(font-feature-settings, "palt");
}

/* site style */
@mixin text-hide {
  overflow: hidden;
  text-indent: -9999px;
}

/* [ writing-mode ]
-------------------------------------------------*/
@mixin writing-mode($orientation: "h", $direction: "rl") {
  @if $orientation == "v" {
    // Vertical with direction, rl by default
    @include add_prefix(writing-mode, vertical- + $direction);
  } @else {
    // Default...
    @include add_prefix(writing-mode, horizontal-tb);
  }
}

/* [ easy breakpoint ]
-------------------------------------------------*/
@mixin PC {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin SP {
  @media (max-width: 767px) {
    @content;
  }
}

//aspect-ratio fixed box
//==================================================
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: " ";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// CLEARFIX
@mixin clearfix() {
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}

//==================================================
// Triangle helper mixin
// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
// @param {Color} $color [currentcolor] - Triangle color
// @param {Length} $size [1em] - Triangle size
// Call | .css::before { @include triangle(bottom, #000, 5px); }
//==================================================
@mixin triangle($direction, $color: currentcolor, $size: 1em, $distance: 5px) {
  @if not index(top right bottom left, $direction) {
    @error "Direction must be either `top`, `right`, `bottom` or `left`.";
  }

  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  margin: $distance;
  border-#{opposite-position($direction)}: ($size * 1.5) solid $color;

  $perpendicular-borders: $size solid transparent;

  @if $direction == top or $direction == bottom {
    border-left: $perpendicular-borders;
    border-right: $perpendicular-borders;
  } @else if $direction == right or $direction == left {
    border-bottom: $perpendicular-borders;
    border-top: $perpendicular-borders;
  }
}

// SINGLE LINE ELLIPSIS
//==================================================
@mixin singleLineEllipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// MULTIPLE LINES ELLIPSIS
//==================================================
@mixin multiLineEllipsis($lineCount: 1) {
  overflow: hidden;
  width: 100%;
  -webkit-line-clamp: $lineCount;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: normal;
}

// FLEXBOX
//==================================================
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($value: 1) {
  @include add_prefix(flex, $value);
}

@mixin flex-wrap($wrap: wrap) {
  @include add_prefix(flex-wrap, $wrap);
}

@mixin align-items($align: center) {
  @include add_prefix(align-items, $align);
}

@mixin align-content($align: space-between) {
  @include add_prefix(align-content, $align);
}

@mixin justify-content($direction: center) {
  @include add_prefix(justify-content, $direction);
}

@mixin flex-direction($direction: column) {
  @include add_prefix(flex-direction, $direction);
}

// ANIMATION KEY-FRAME
//==================================================
@mixin keyframes($content) {
  @-webkit-keyframes #{$content} {
    @content;
  }
  @keyframes #{$content} {
    @content;
  }
}

// FONT
//==================================================
@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}
@mixin font($size: "", $line: "", $letter: "", $weight: "") {
  @if $size != "" {
    $size-no-unit: strip-unit($size);
    font-size: $size-no-unit + px;
    font-size: ($size-no-unit) / 10 + rem;
  }
  @if $line != "" {
    $line-no-unit: strip-unit($line);
    @if $line-no-unit < 6 {
      line-height: $line-no-unit + em;
    } @else {
      @if $size != "" {
        $size-no-unit: strip-unit($size);
        line-height: ($line-no-unit / $size-no-unit) + em;
      } @else {
        line-height: ($line-no-unit) / 10 + rem;
      }
    }
  }
  @if $letter != "" {
    $letter-no-unit: strip-unit($letter);
    @if $letter-no-unit > 9 or $letter-no-unit < 0 {
      letter-spacing: ($letter-no-unit / 1000) + em;
    } @else {
      letter-spacing: $letter-no-unit + em;
    }
  }
  @if $weight != "" {
    font-weight: $weight;
  }
}
