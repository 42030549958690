@charset 'UTF-8';
@import "/Users/nguyenhieu/Websites/vhost/yamaha-renew/src/scss/utility/_media-queries.scss";
@import "/Users/nguyenhieu/Websites/vhost/yamaha-renew/src/scss/utility/_mixin.scss";


.page-404{
  &__content{
    box-sizing: border-box;
  }
  &__left{
    line-height: 1.7;
  }
  &__right{
    >.btn{
      @include PC {
        width: 210px;
      }
    }
  }
}